@tailwind base;
@tailwind components;
@tailwind utilities;

.tabs-wrapper {

  border-radius: 35px;
  overflow: hidden;

  height: 45px;
  padding: 3px;
  background-color: rgb(218, 218, 218);
}
.tabs-wrapper * {
  transition: 0.2s ease-in-out;
}
.tabs-wrapper button {
  width: 50%;
  color: rgb(113, 113, 113);
  height: 100%;
  background-color: transparent;
  border-radius: 35px;
  font-weight: 600;
}
.background-image-container {
  background-image: url('./assets/line.png');
  background-size: cover; /* Adjust as needed */
  /* background-position: center center;  */
  /* Add more styles as needed */
}

.ant-menu-item-selected {
  background-color: #eab308 !important;
  color: #000000 !important;
}

.tabs-wrapper button.active {
  background: #eab308;
  color: #141414;
}
.ant-layout-footer {
  padding: 0 !important;
}
.vertical-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text */
}
a:hover {
  color: #eab308;
}
/* .ant-table-wrapper .ant-table-thead>tr>th {
  padding: 0px;
} */
.ant-table-wrapper .ant-table-tbody  >tr >td {
  padding: 5px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  margin-bottom: 10px;
  vertical-align: top;
}
.ant-btn-primary {
  color: #fff;
  background-color: #eab308;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.active-switch {
  background-color: green; /* Custom color for active state */
}
.completed-icon {
  color: green; /* Custom color for completed status */
}
.approved-icon {
  color: blue; /* Custom color for approved status */
}

.dispute-icon {
  color: orange; /* Custom color for dispute status */
}

.cancel-icon {
  color: red; /* Custom color for cancel status */
}
.inactive-switch {
  background-color: red; /* Custom color for inactive state */
}
.ant-layout-footer {
  width: "100%";
  padding: 4;
}
.client-left .success-text  {
  max-width: 80%;
  margin-left:auto;
  width: fit-content;

}
.profile-letter-chat {
  width: 30px;
  height: 30px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 1000px;
  background-color: rgb(26, 28, 40);
}
.profile-letter {
  width: 40px;
  height: 40px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  border-radius: 1000px;
  background-color:#fff;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollbar-none {
  scrollbar-width: none;
}
/* App.css */
/* :root {
  --primary-color-light: #1890ff;
  --primary-color-dark: #00bcd4;
}

.light {
  --primary-color: var(--primary-color-light);
}

.dark {
  --primary-color: var(--primary-color-dark);
} */

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: fixed;
  top: 80px !important;
  width: 100%;
  pointer-events: none;
  z-index: 1010;
}